import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { withRouter } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import { createBrowserHistory } from "history";
const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register = lazy(() => import("./user-pages/Register"));
const ForgotPassword = lazy(() => import("./user-pages/ForgotPassword"));
const UserList = lazy(() => import("./user-pages/UserList"));
const ProductList = lazy(() => import("./products/ProductList"));
const CompanyList = lazy(() => import("./companies/CompanyList"));
const EditCompany = lazy(() => import("./companies/EditCompany"));
const AddProduct = lazy(() => import("./products/AddProduct"));
const OrderList = lazy(() => import("./orders/OrderList"));
const CoupanList = lazy(() => import("./coupan/CoupanList"));
const AddCoupan = lazy(() => import("./coupan/AddCoupan"));
const AddUser = lazy(() => import("./user-pages/AddUser"));
const PaymentKeys = lazy(() => import("./payment-keys/paymentKeys"));
const Settings = lazy(()=>import("./settings/settings"))

const history = createBrowserHistory();
class AppRoutes extends Component {
  render() {
    return (
      <Provider store={configureStore()}>
        {/* <Router history={history}> */}
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/sign-up" component={Register} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/add-user" component={AddUser} />
            <Route path="/user-list" component={UserList} />
            <Route path="/home" component={Dashboard} />
            <Route path="/product-list" component={ProductList} />
            <Route path="/company-list" component={CompanyList} />
            <Route path="/company-edit" component={EditCompany} />
            <Route path="/add-product" component={AddProduct} />
            <Route path="/order-list" component={OrderList} />
            <Route path="/coupan-list" component={CoupanList} />
            <Route path="/add-coupan" component={AddCoupan} />
            <Route path="/settings" component={Settings} />
            {/* <Route path="/basic-ui/buttons" component={Buttons} />
            <Route path="/basic-ui/dropdowns" component={Dropdowns} />
            <Route path="/basic-ui/typography" component={Typography} />

            <Route
              path="/form-Elements/basic-elements"
              component={BasicElements}
            />

            <Route path="/tables/basic-table" component={BasicTable} />

            <Route path="/icons/mdi" component={Mdi} />

            <Route path="/charts/chart-js" component={ChartJs} /> */}

            <Route path="/error-pages/error-404" component={Error404} />
            <Route path="/error-pages/error-500" component={Error500} />

            <Redirect to="/" />
          </Switch>
        </Suspense>
        {/* </Router> */}
      </Provider>
    );
  }
}

export default withRouter(AppRoutes);
