import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Router } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import history from "./app/history";

ReactDOM.render(
  <BrowserRouter basename="/">
    <Router history={history}>
    <App />
    </Router>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
