import React, { Component } from 'react'

export class Spinner extends Component {
  render() {
    return (
      <div className='main-spinner-wrapper'>
       <div class="spinner-border text-warning" role="status">
  <span class="sr-only">Loading...</span>
</div>
      </div>
    )
  }
}

export default Spinner
