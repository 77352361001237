const INIT_STATE = {
    error:'',
    in_action:false,
    user_login:''
}

export default(state = INIT_STATE,action) => {
    switch(action.type){
        
        case "ACTION_INITIATED_ADD_USER":
        return {
            ...state,
            in_action: true
        };
        case "SUCCESS_LOGIN" :
            return {
                ...state,
                user_login: action.payload
            }
            case "FAILURE_USER_LOGIN" :
            return {
                ...state,
                error: action.payload,
                user_login:''
            }
            case "ACTION_COMPLETED_LOGIN" : 
            return {
                ...state,
                in_action: false
            }
            default:
                return state
    }
}