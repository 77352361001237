const INIT_STATE = {
    error:'',
    in_action:false,
    product_added:''
}

export default(state = INIT_STATE,action) => {

    switch(action.type){
        case "ACTION_INITIATED_ADD_PRODUCT":
        return {
            ...state,
            in_action: true
        };
        case "SUCCESS_ADD_PRODUCT" :
            return {
                ...state,
                product_added: action.payload
            }
            case "FAILURE_ADD_PRODUCT" :
            return {
                ...state,
                error: action.payload
            }
            case "ACTION_COMPLETED__ADD_PRODUCT" : 
            return {
                ...state,
                in_action: false
            }
            default:
                return state
    }
}