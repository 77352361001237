const INIT_STATE = {
    error: "",
    in_action: false,
    companies_list: "",
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case "ACTION_INITIATED_COMPANIES":
        return {
          ...state,
          in_action: true,
        };
      case "SUCCESS_COMPANIES_LIST":
        return {
          ...state,
          companies_list: action.payload,
        };
      case "FAILURE_COMPANIES_LIST":
        return {
          ...state,
          error: action.payload,
          companies_list: "",
        };
      case "ACTION__COMPANIES_LIST":
        return {
          ...state,
          in_action: false,
        };
      default:
        return state;
    }
  };
  