const INIT_STATE = {
    error: "",
    in_action: false,
    product_list: "",
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case "ACTION_INITIATED_ADD_USER":
        return {
          ...state,
          in_action: true,
        };
      case "SUCCESS_PRODUCT_LIST":
        return {
          ...state,
          product_list: action.payload,
        };
      case "FAILURE_PRODUCT_LIST":
        return {
          ...state,
          error: action.payload,
          product_list: "",
        };
      case "ACTION__PRODUCT_LIST":
        return {
          ...state,
          in_action: false,
        };
      default:
        return state;
    }
  };
  