const INIT_STATE = {
    error: "",
    in_action: false,
    add_coupan: "",
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
    //   case "ACTION_INITIATED_ADD_USER":
    //     return {
    //       ...state,
    //       in_action: true,
    //     };
      case "SUCCESS_ADD_COUPAN":
        return {
          ...state,
          add_coupan: action.payload,
        };
      case "FAILURE_USER_ADD_COUPAN":
        return {
          ...state,
          error: action.payload,
          add_coupan: "",
        };
      case "ACTION_COMPLETED_ADD_COUPAN":
        return {
          ...state,
          in_action: false,
        };
      default:
        return state;
    }
  };
  