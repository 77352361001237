import {combineReducers} from "redux"
import user_added from "../redux/register/reducer";
import password_reset from "../redux/forgotPassword/reducer";
import user_login from "../redux/login/reducer";
import product_added from "../redux/addProduct/reducer";
import product_list from "../redux/productList/reducer";
import companies_list from "../redux/companies/reducer";
import add_coupan from "../redux/coupan/reducer";

const appReducer = combineReducers ({
    user_added,
    password_reset,
    user_login,
    product_added,
    product_list,
    companies_list,
    add_coupan
});

const root_reducer = (state,action)=>{
    if(action.type === 'USER_LOGOUT'){
        state = undefined;
    }
    return appReducer(state,action);
};
export default root_reducer;